<template>
	 <b-modal ref="modal" hide-footer>
        <template v-slot:modal-title>
       		{{ $t("invoice.edit_ht_lot") }}
        </template>

        <div class="form-group">
        	<label for="ht_initial">{{ $t('invoice.ht_inital') }} *</label>
        	<PriceInput v-model="ht_initial" />
        </div>
		<div class="text-center">
			<b-button variant="primary" @click="validForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['far', 'pencil-alt']" /> {{ $t('global.modifier') }}</b-button>
		</div>
    </b-modal>
</template>

<script type="text/javascript">
    import Invoice from "@/mixins/Invoice.js"

	export default {
		name: 'ModalEditHTLot',
		mixins: [Invoice],
		data () {
			return {
				invoice_details: [],
				ht_initial: 0,
				processing: false
			}
		},

		methods: {
			async openModal(invoice_details) {
				this.invoice_details = invoice_details
				this.ht_initial = this.invoice_details[0].invoicedetails_ht_initial
				this.$refs.modal.show()
			},

			async validForm() {
				this.processing = true
				await this.editLotHT(this.invoice_details.map(details => details.invoicedetails_id), this.ht_initial);
				this.processing = false

				this.$refs.modal.hide()
				this.$emit('submit')
			}
		},

		components: {
			PriceInput: () => import('GroomyRoot/components/Inputs/PriceInput')
		}
	}

</script>